import React, { createContext } from 'react';
import { Navigation } from './common/utils';
import AnimationLayer from './AnimationLayer';
import GhostLayer from './GhostLayer';
var AnimationDirectionEnum;
(function (AnimationDirectionEnum) {
    AnimationDirectionEnum[AnimationDirectionEnum["up"] = 0] = "up";
    AnimationDirectionEnum[AnimationDirectionEnum["down"] = 1] = "down";
    AnimationDirectionEnum[AnimationDirectionEnum["left"] = 2] = "left";
    AnimationDirectionEnum[AnimationDirectionEnum["right"] = 3] = "right";
    AnimationDirectionEnum[AnimationDirectionEnum["in"] = 4] = "in";
    AnimationDirectionEnum[AnimationDirectionEnum["out"] = 5] = "out";
})(AnimationDirectionEnum || (AnimationDirectionEnum = {}));
var AnimationTypeEnum;
(function (AnimationTypeEnum) {
    AnimationTypeEnum[AnimationTypeEnum["slide"] = 0] = "slide";
    AnimationTypeEnum[AnimationTypeEnum["fade"] = 1] = "fade";
    AnimationTypeEnum[AnimationTypeEnum["zoom"] = 2] = "zoom";
    AnimationTypeEnum[AnimationTypeEnum["none"] = 3] = "none";
})(AnimationTypeEnum || (AnimationTypeEnum = {}));
export class RouterData {
    constructor() {
        this._currentPath = '';
        this._routesData = {};
        this._navigation = new Navigation(false);
        this._backNavigating = false;
        this._animation = {
            in: {
                type: "none",
                duration: 0,
            },
            out: {
                type: "none",
                duration: 0
            }
        };
        this._ghostLayer = null;
    }
    set currentPath(_currentPath) {
        this._currentPath = _currentPath;
    }
    set routesData(_routesData) {
        this._routesData = _routesData;
    }
    set navigation(_navigation) {
        this._navigation = _navigation;
    }
    set animation(_animation) {
        this._animation = _animation;
    }
    set ghostLayer(_ghostLayer) {
        this._ghostLayer = _ghostLayer;
    }
    set backNavigating(_backNavigating) {
        this._backNavigating = _backNavigating;
    }
    get currentPath() {
        return this._currentPath;
    }
    get routesData() {
        return this._routesData;
    }
    get navigation() {
        return this._navigation;
    }
    get animation() {
        return this._animation;
    }
    get ghostLayer() {
        return this._ghostLayer;
    }
    get backNavigating() {
        return this._backNavigating;
    }
}
export const RouterDataContext = createContext(new RouterData());
export default class Router extends React.Component {
    constructor(props) {
        super(props);
        this.navigation = new Navigation(this.props.config.disableBrowserRouting || false);
        this._pageLoad = true;
        this.onBackListener = this.onBack.bind(this);
        this.onNavigateListener = this.onNavigate.bind(this);
        this.onPopStateListener = this.onPopstate.bind(this);
        this.state = {
            currentPath: "",
            backNavigating: false,
            routesData: {},
            implicitBack: false
        };
        if (props.config) {
            this.config = props.config;
        }
        else {
            this.config = {
                animation: {
                    in: {
                        type: "none",
                        duration: 0,
                    },
                    out: {
                        type: "none",
                        duration: 0,
                    }
                }
            };
        }
        this._routerData = new RouterData();
        this._routerData.navigation = this.navigation;
        if ('in' in this.config.animation) {
            this._routerData.animation = {
                in: this.config.animation.in,
                out: this.config.animation.out || this.config.animation.in
            };
        }
        else {
            this._routerData.animation = {
                in: this.config.animation,
                out: this.config.animation
            };
        }
    }
    componentDidMount() {
        if (this.props.config.defaultRoute) {
            this.navigation.history.defaultRoute = this.props.config.defaultRoute;
        }
        // get url search params and append to existing route params
        const searchParams = this.navigation.history.searchParamsToObject(window.location.search);
        const routesData = this.state.routesData;
        if (searchParams) {
            routesData[window.location.pathname] = {
                params: searchParams
            };
        }
        let currentPath = window.location.pathname;
        if (this.props.config.defaultRoute && window.location.pathname === '/' && this.props.config.defaultRoute !== '/') {
            this.navigation.navigate(this.props.config.defaultRoute);
            currentPath = this.props.config.defaultRoute;
        }
        this.setState({ currentPath: currentPath, routesData: routesData }, () => {
            this._routerData.routesData = this.state.routesData;
        });
        this._routerData.currentPath = window.location.pathname;
        window.addEventListener('go-back', this.onBackListener, true);
        window.addEventListener('popstate', this.onPopStateListener, true);
        window.addEventListener('navigate', this.onNavigateListener, true);
    }
    componentWillUnmount() {
        window.removeEventListener('navigate', this.onNavigateListener);
        window.removeEventListener('popstate', this.onPopStateListener);
        window.removeEventListener('go-back', this.onBackListener);
    }
    onAnimationEnd() {
        if (this.state.backNavigating) {
            this._routerData.backNavigating = false;
            this.setState({ backNavigating: false });
        }
    }
    onPopstate(e) {
        e.preventDefault();
        this._pageLoad = false;
        if (window.location.pathname === this.navigation.history.previous) {
            if (!this.state.implicitBack) {
                this.setState({ backNavigating: true });
                this._routerData.backNavigating = true;
            }
            else {
                this.setState({ implicitBack: false });
            }
            this.navigation.implicitBack();
        }
        else {
            if (!this.state.backNavigating && !this.state.implicitBack) {
                this.navigation.implicitNavigate(window.location.pathname);
            }
        }
        window.addEventListener('page-animation-end', this.onAnimationEnd.bind(this), { once: true });
        this._routerData.currentPath = window.location.pathname;
        this.setState({ currentPath: window.location.pathname });
    }
    onBack(e) {
        this.setState({ backNavigating: true });
        this._pageLoad = false;
        let pathname = window.location.pathname;
        if (this.config.disableBrowserRouting) {
            pathname = this.navigation.history.current || pathname;
        }
        if (e.detail.replaceState && !this.config.disableBrowserRouting) { // replaced state with default route
            this._routerData.currentPath = pathname;
            this.setState({ currentPath: pathname });
        }
        if (this.config.disableBrowserRouting) {
            this._routerData.currentPath = pathname;
            this.setState({ currentPath: pathname });
            if (this.state.implicitBack) {
                this.setState({ implicitBack: false });
            }
        }
        window.addEventListener('page-animation-end', this.onAnimationEnd.bind(this), { once: true });
        this._routerData.backNavigating = true;
    }
    onNavigate(e) {
        e.preventDefault();
        this._pageLoad = false;
        const currentPath = e.detail.route;
        this._routerData.currentPath = currentPath;
        if (e.detail.routeParams) {
            const routesData = this.state.routesData;
            //store per route data in object
            //with pathname as key and route data as value
            routesData[currentPath] = {
                params: e.detail.routeParams
            };
            this.setState({ routesData: routesData }, () => {
                this._routerData.routesData = routesData;
                this.setState({ currentPath: currentPath });
            });
        }
        else {
            this.setState({ currentPath: currentPath });
        }
    }
    render() {
        return (React.createElement("div", { className: "react-motion-router" },
            React.createElement(RouterDataContext.Provider, { value: this._routerData },
                React.createElement(GhostLayer, { animation: this._routerData.animation, instance: (instance) => {
                        this._routerData.ghostLayer = instance;
                    }, backNavigating: this.state.backNavigating }),
                React.createElement(AnimationLayer, { disableBrowserRouting: this.props.config.disableBrowserRouting || false, disableDiscovery: this.props.config.disableDiscovery || false, hysteresis: this.props.config.hysteresis || 50, minFlingVelocity: this.props.config.minFlingVelocity || 400, swipeAreaWidth: this.props.config.swipeAreaWidth || 100, navigation: this._routerData.navigation, duration: this._routerData.animation.in.duration, currentPath: this.state.currentPath, backNavigating: this.state.backNavigating, lastPath: this.navigation.history.previous, goBack: () => {
                        this.setState({ implicitBack: true }, () => {
                            this.navigation.goBack();
                        });
                    } }, this.props.children))));
    }
}
Router.defaultProps = {
    config: {
        animation: {
            in: {
                type: "fade",
                duration: 200,
                direction: "none"
            },
            out: {
                type: "fade",
                duration: 200,
                direction: "none"
            }
        }
    }
};

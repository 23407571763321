import React from 'react';
import { clamp } from './common/utils';
import { getStyleObject } from './common/utils';
export default class GhostLayer extends React.Component {
    constructor() {
        super(...arguments);
        this.ref = null;
        this._currentScene = null;
        this._nextScene = null;
        this._animationMap = {};
        this.onProgressStartListener = this.onProgressStart.bind(this);
        this.onProgressListener = this.onProgress.bind(this);
        this.onProgressEndListener = this.onProgressEnd.bind(this);
        this.state = {
            transitioning: false,
            playing: true
        };
    }
    set currentScene(scene) {
        this._currentScene = scene;
    }
    set nextScene(scene) {
        this._nextScene = scene;
        if (this._currentScene) {
            if (!this._currentScene.isEmpty() && !this._nextScene.isEmpty()) {
                this.sharedElementTransition(this._currentScene, this._nextScene);
                return;
            }
        }
        this._currentScene = null;
        this._nextScene = null;
    }
    sharedElementTransition(currentScene, nextScene) {
        if (this.props.animation.in.type === "none")
            return;
        if (this.props.backNavigating && this.props.animation.out.type === "none")
            return;
        if (this.props.animation.in.duration === 0)
            return;
        if (this.props.backNavigating && this.props.animation.out.duration === 0)
            return;
        this.setState({ transitioning: true }, () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
            //if id exists in next scene
            for (const id in currentScene.nodes) {
                if (Object.keys(nextScene.nodes).includes(id)) {
                    const endInstance = nextScene.nodes[id].instance;
                    const startInstance = currentScene.nodes[id].instance;
                    startInstance.hidden = true;
                    endInstance.hidden = true;
                    const startNode = currentScene.nodes[id].node;
                    const endNode = nextScene.nodes[id].node;
                    const transitionState = {
                        id: startInstance.id,
                        start: {
                            x: {
                                node: startNode,
                                duration: ((_b = (_a = startInstance.props.config) === null || _a === void 0 ? void 0 : _a.x) === null || _b === void 0 ? void 0 : _b.duration) || ((_c = endInstance.props.config) === null || _c === void 0 ? void 0 : _c.duration) || this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration,
                                easingFunction: ((_e = (_d = startInstance.props.config) === null || _d === void 0 ? void 0 : _d.x) === null || _e === void 0 ? void 0 : _e.easingFunction) || ((_f = startInstance.props.config) === null || _f === void 0 ? void 0 : _f.easingFunction) || 'ease',
                                position: parseFloat(startNode.getAttribute('x') || '0') - currentScene.x,
                            },
                            y: {
                                node: startNode.firstElementChild,
                                duration: ((_h = (_g = startInstance.props.config) === null || _g === void 0 ? void 0 : _g.y) === null || _h === void 0 ? void 0 : _h.duration) || ((_j = endInstance.props.config) === null || _j === void 0 ? void 0 : _j.duration) || this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration,
                                easingFunction: ((_l = (_k = startInstance.props.config) === null || _k === void 0 ? void 0 : _k.y) === null || _l === void 0 ? void 0 : _l.easingFunction) || ((_m = startInstance.props.config) === null || _m === void 0 ? void 0 : _m.easingFunction) || 'ease',
                                position: parseFloat(startNode.getAttribute('y') || '0') - currentScene.y
                            }
                        },
                        end: {
                            x: {
                                node: endNode,
                                duration: ((_p = (_o = endInstance.props.config) === null || _o === void 0 ? void 0 : _o.x) === null || _p === void 0 ? void 0 : _p.duration) || ((_q = endInstance.props.config) === null || _q === void 0 ? void 0 : _q.duration) || this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration,
                                easingFunction: ((_s = (_r = endInstance.props.config) === null || _r === void 0 ? void 0 : _r.x) === null || _s === void 0 ? void 0 : _s.easingFunction) || ((_t = endInstance.props.config) === null || _t === void 0 ? void 0 : _t.easingFunction) || 'ease',
                                position: parseFloat(endNode.getAttribute('x') || '0') - nextScene.x
                            },
                            y: {
                                node: endNode.firstElementChild,
                                duration: ((_v = (_u = endInstance.props.config) === null || _u === void 0 ? void 0 : _u.y) === null || _v === void 0 ? void 0 : _v.duration) || ((_w = endInstance.props.config) === null || _w === void 0 ? void 0 : _w.duration) || this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration,
                                easingFunction: ((_y = (_x = endInstance.props.config) === null || _x === void 0 ? void 0 : _x.x) === null || _y === void 0 ? void 0 : _y.easingFunction) || ((_z = endInstance.props.config) === null || _z === void 0 ? void 0 : _z.easingFunction) || 'ease',
                                position: parseFloat(endNode.getAttribute('y') || '0') - nextScene.y
                            }
                        }
                    };
                    startNode.style.display = 'unset';
                    endNode.style.display = 'unset';
                    const startZIndex = parseInt(startNode.style.zIndex) || 0;
                    const endZIndex = parseInt(endNode.style.zIndex) || 0;
                    endNode.style.zIndex = `${clamp(endZIndex, 0, startZIndex - 1)}`;
                    (_0 = this.ref) === null || _0 === void 0 ? void 0 : _0.appendChild(startNode);
                    const transitionType = endInstance.transitionType || startInstance.transitionType || 'morph';
                    if (transitionType !== "morph") {
                        (_1 = this.ref) === null || _1 === void 0 ? void 0 : _1.appendChild(endNode);
                    }
                    const endTravelDistance = {
                        x: 0,
                        y: 0
                    };
                    const startTravelDistance = {
                        x: 0,
                        y: 0
                    };
                    const startRect = startInstance.clientRect;
                    const endRect = endInstance.clientRect;
                    if (startInstance.scene) {
                        if (transitionState.start.y.position - startRect.y >= startInstance.scene.scrollPos.y) {
                            startTravelDistance.y = transitionState.start.y.position > startInstance.scene.scrollPos.y ? startInstance.scene.scrollPos.y : transitionState.start.y.position;
                        }
                        if (transitionState.start.x.position - startRect.x >= startInstance.scene.scrollPos.x) {
                            startTravelDistance.x = transitionState.start.x.position > startInstance.scene.scrollPos.x ? startInstance.scene.scrollPos.x : transitionState.start.x.position;
                        }
                    }
                    if (endInstance.scene) {
                        if (transitionState.end.y.position - endRect.y >= endInstance.scene.scrollPos.y) {
                            endTravelDistance.y = transitionState.end.y.position > endInstance.scene.scrollPos.y ? endInstance.scene.scrollPos.y : transitionState.end.y.position;
                        }
                        if (transitionState.end.x.position - endRect.x >= endInstance.scene.scrollPos.x) {
                            endTravelDistance.x = transitionState.end.x.position > endInstance.scene.scrollPos.x ? endInstance.scene.scrollPos.x : transitionState.end.x.position;
                        }
                    }
                    /**
                     * KNOWN ISSUES:
                     * 1. if page 2 scroll position is falsely (0, 0) elements might fail to transition properly.
                     *    has a lot to do with how scrolling works in this implementation.
                     */
                    transitionState.start.x.position = Math.abs(transitionState.start.x.position - startTravelDistance.x);
                    transitionState.start.y.position = Math.abs(transitionState.start.y.position - startTravelDistance.y);
                    transitionState.end.x.position = Math.abs(transitionState.end.x.position - endTravelDistance.x);
                    transitionState.end.y.position = Math.abs(transitionState.end.y.position - endTravelDistance.y);
                    let startXAnimation;
                    let startYAnimation;
                    let endXAnimation;
                    let endYAnimation;
                    if (transitionType === "morph") {
                        startXAnimation = transitionState.start.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`
                            },
                            Object.assign(Object.assign({}, getStyleObject(transitionState.end.x.node.style)), { transform: `translate(${transitionState.end.x.position}px, 0px)` })
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        startYAnimation = transitionState.start.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            Object.assign(Object.assign({}, getStyleObject(transitionState.end.y.node.style)), { transform: `translate(0px, ${transitionState.end.y.position}px)` })
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        this._animationMap[startInstance.id] = { startXAnimation, startYAnimation };
                    }
                    else if (transitionType === "fade") {
                        startXAnimation = transitionState.start.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`,
                                opacity: 1
                            },
                            {
                                transform: `translate(${transitionState.end.x.position}px, 0px)`,
                                opacity: 0
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        startYAnimation = transitionState.start.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            {
                                transform: `translate(0px, ${transitionState.end.y.position}px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        endXAnimation = transitionState.end.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`
                            },
                            {
                                transform: `translate(${transitionState.end.x.position}px, 0px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        endYAnimation = transitionState.end.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            {
                                transform: `translate(0px, ${transitionState.end.y.position}px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        this._animationMap[startInstance.id] = { startXAnimation, startYAnimation, endXAnimation, endYAnimation };
                    }
                    else if (transitionType === "fade-through") {
                        startXAnimation = transitionState.start.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`,
                                opacity: 1
                            },
                            {
                                opacity: 0,
                                offset: 0.5
                            },
                            {
                                transform: `translate(${transitionState.end.x.position}px, 0px)`,
                                opacity: 0
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        startYAnimation = transitionState.start.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            {
                                transform: `translate(0px, ${transitionState.end.y.position}px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        endXAnimation = transitionState.end.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`,
                                opacity: 0
                            },
                            {
                                opacity: 0,
                                offset: 0.5
                            },
                            {
                                transform: `translate(${transitionState.end.x.position}px, 0px)`,
                                opacity: 1
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        endYAnimation = transitionState.end.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            {
                                transform: `translate(0px, ${transitionState.end.y.position}px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        this._animationMap[startInstance.id] = { startXAnimation, startYAnimation, endXAnimation, endYAnimation };
                    }
                    else { // cross-fade
                        startXAnimation = transitionState.start.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`,
                                opacity: 1
                            },
                            {
                                transform: `translate(${transitionState.end.x.position}px, 0px)`,
                                opacity: 0
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        startYAnimation = transitionState.start.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            {
                                transform: `translate(0px, ${transitionState.end.y.position}px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        endXAnimation = transitionState.end.x.node.animate([
                            {
                                transform: `translate(${transitionState.start.x.position}px, 0px)`,
                                opacity: 0
                            },
                            {
                                transform: `translate(${transitionState.end.x.position}px, 0px)`,
                                opacity: 1
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.x.easingFunction,
                            duration: clamp(transitionState.end.x.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        endYAnimation = transitionState.end.y.node.animate([
                            {
                                transform: `translate(0px, ${transitionState.start.y.position}px)`
                            },
                            {
                                transform: `translate(0px, ${transitionState.end.y.position}px)`
                            }
                        ], {
                            fill: 'forwards',
                            easing: transitionState.end.y.easingFunction,
                            duration: clamp(transitionState.end.y.duration, 0, this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration)
                        });
                        this._animationMap[startInstance.id] = { startXAnimation, startYAnimation, endXAnimation, endYAnimation };
                    }
                    if (!this.state.playing) {
                        Object.values(this._animationMap).map((xYAnimations) => {
                            Object.values(xYAnimations).map((animation) => {
                                animation.pause();
                            });
                        });
                    }
                    window.addEventListener('page-animation-end', () => {
                        var _a, _b;
                        startInstance.hidden = false;
                        endInstance.hidden = false;
                        (_a = this.ref) === null || _a === void 0 ? void 0 : _a.removeChild(startNode);
                        if (transitionType !== "morph") {
                            (_b = this.ref) === null || _b === void 0 ? void 0 : _b.removeChild(endNode);
                        }
                    }, { once: true });
                }
            }
        });
        window.addEventListener('page-animation-end', () => {
            this.setState({ transitioning: false });
            this._nextScene = null;
            this._currentScene = null;
        }, { once: true });
    }
    componentDidMount() {
        if (this.props.instance) {
            this.props.instance(this);
        }
        window.addEventListener('motion-progress-start', this.onProgressStartListener);
        window.addEventListener('motion-progress', this.onProgressListener);
        window.addEventListener('motion-progress-end', this.onProgressEndListener);
    }
    componentWillUnmount() {
        window.removeEventListener('motion-progress-start', this.onProgressStartListener);
        window.removeEventListener('motion-progress', this.onProgressListener);
        window.removeEventListener('motion-progress-end', this.onProgressEndListener);
    }
    onProgressStart() {
        this.setState({ playing: false });
    }
    onProgress(e) {
        if (!this.state.playing) {
            Object.values(this._animationMap).map((xYAnimations) => {
                Object.values(xYAnimations).map((animation) => {
                    var _a;
                    const progress = e.detail.progress; // because ghost layer animations never run backwards
                    const defaultDuration = this.props.backNavigating ? this.props.animation.out.duration : this.props.animation.in.duration;
                    let duration = ((_a = animation.effect) === null || _a === void 0 ? void 0 : _a.getComputedTiming().duration) || defaultDuration;
                    if (typeof duration === "string") {
                        duration = parseFloat(duration);
                    }
                    const currentTime = (progress / 100) * duration;
                    animation.currentTime = currentTime;
                });
            });
        }
    }
    onProgressEnd() {
        this.setState({ playing: true, transitioning: false });
    }
    render() {
        if (this.state.transitioning) {
            return (React.createElement("div", { id: "ghost-layer", ref: c => this.ref = c, style: {
                    position: 'absolute',
                    zIndex: 1000,
                    width: '100vw',
                    height: '100vh',
                    pointerEvents: 'none'
                } }));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    }
}

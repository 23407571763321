var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext } from 'react';
import { clamp } from './common/utils';
import AnimationKeyframePresets from './Animations';
export class AnimationLayerData {
    constructor() {
        this._progress = 0;
        this._play = true;
        this._currentScreen = null;
        this._nextScreen = null;
        this._progressUpdateID = 0;
        this._duration = 0;
        this._playbackRate = 1;
        this._gestureNavigating = false;
        this._onEnd = null;
        this._onProgress = null;
        this._shouldAnimate = true;
    }
    updateProgress() {
        var _a;
        if (this._gestureNavigating && !this._play) {
            // update in set progress() instead
            window.cancelAnimationFrame(this._progressUpdateID);
            return;
        }
        const update = () => {
            var _a;
            const currentTime = ((_a = this._outAnimation) === null || _a === void 0 ? void 0 : _a.currentTime) || 0;
            const progress = clamp((currentTime / this._duration) * 100, 0, 100);
            this._progress = progress;
            if (this._onProgress) {
                this._onProgress(this._progress);
            }
        };
        update();
        this._progressUpdateID = window.requestAnimationFrame(this.updateProgress.bind(this));
        (_a = this._outAnimation) === null || _a === void 0 ? void 0 : _a.finished.then(() => {
            window.cancelAnimationFrame(this._progressUpdateID);
            if (this._progress !== 100) {
                update();
            }
        });
    }
    reset() {
        this._onEnd = null;
        this._playbackRate = 1;
        this._play = true;
        this._progress = 0;
        this._gestureNavigating = false;
    }
    finish() {
        if (this._inAnimation && this._outAnimation) {
            this._inAnimation.finish();
            this._outAnimation.finish();
        }
    }
    cancel() {
        if (this._inAnimation && this._outAnimation) {
            this._inAnimation.cancel();
            this._outAnimation.cancel();
        }
    }
    animate() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (this._currentScreen && this._nextScreen && this._shouldAnimate) {
                if (this._gestureNavigating) {
                    yield this._currentScreen.mounted(true);
                    this._nextScreen.pointerEvents = 'none';
                    this._currentScreen.pointerEvents = 'unset';
                }
                else {
                    this._currentScreen.pointerEvents = 'none';
                    this._nextScreen.pointerEvents = 'unset';
                }
                // failing to call _onExit to disable SETs
                if (this._onExit && this._shouldAnimate)
                    this._onExit();
                yield this._nextScreen.mounted(true);
                let easingFunction = 'ease-out';
                if (this._gestureNavigating)
                    easingFunction = 'linear';
                this._outAnimation = this._currentScreen.animate(AnimationKeyframePresets[this._currentScreen.outAnimation], {
                    fill: 'forwards',
                    duration: this._duration,
                    easing: easingFunction
                });
                this._inAnimation = this._nextScreen.animate(AnimationKeyframePresets[this._nextScreen.inAnimation], {
                    fill: 'forwards',
                    duration: this._duration,
                    easing: easingFunction
                });
                (_a = this._inAnimation) === null || _a === void 0 ? void 0 : _a.play();
                (_b = this._outAnimation) === null || _b === void 0 ? void 0 : _b.play();
                if (this._inAnimation && this._outAnimation) {
                    if (!this._shouldAnimate) {
                        this._inAnimation.finish();
                        this._outAnimation.finish();
                        this._shouldAnimate = true;
                        return;
                    }
                    this._inAnimation.playbackRate = this._playbackRate;
                    this._outAnimation.playbackRate = this._playbackRate;
                    if (this._gestureNavigating) {
                        this._inAnimation.currentTime = this._duration;
                        this._outAnimation.currentTime = this._duration;
                    }
                    if (!this._play) {
                        this._inAnimation.pause();
                        this._outAnimation.pause();
                    }
                    this._outAnimation.ready.then(() => {
                        this.updateProgress();
                    });
                    this._outAnimation.onfinish = () => {
                        if (this._outAnimation) {
                            this._outAnimation.onfinish = null;
                        }
                        // if playback rate is 2 then gesture navigation was aborted
                        if (!this._gestureNavigating || this._playbackRate === 0.5) {
                            if (this._currentScreen) {
                                this._currentScreen.mounted(false);
                            }
                        }
                        else {
                            if (this._currentScreen) {
                                // hotfix for weird bug that snaps screen to start position after gesture navigation
                                this._currentScreen.animate([
                                    { transform: 'translateX(0vw)' }
                                ], { duration: 0, fill: 'forwards' });
                            }
                            if (this._nextScreen) {
                                this._nextScreen.mounted(false);
                            }
                        }
                        if (this._onEnd) {
                            this._onEnd();
                        }
                        const endAnimationEvent = new CustomEvent('page-animation-end');
                        window.dispatchEvent(endAnimationEvent);
                    };
                }
            }
            else {
                this._shouldAnimate = true;
            }
        });
    }
    set onProgress(_onProgress) {
        this._onProgress = _onProgress;
    }
    set onEnd(_onEnd) {
        this._onEnd = _onEnd;
    }
    set shouldAnimate(_shouldAnimate) {
        this._shouldAnimate = _shouldAnimate;
    }
    set playbackRate(_playbackRate) {
        this._playbackRate = _playbackRate;
        if (_playbackRate > 0) {
            // aborted gesture navigation so set pointer events back to correct setting
            if (this._currentScreen && this._nextScreen) {
                this._currentScreen.pointerEvents = 'none';
                this._nextScreen.pointerEvents = 'unset';
            }
        }
        if (this._inAnimation && this._outAnimation) {
            this._inAnimation.playbackRate = this._playbackRate;
            this._outAnimation.playbackRate = this._playbackRate;
        }
    }
    set gestureNavigating(_gestureNavigating) {
        this._gestureNavigating = _gestureNavigating;
    }
    set play(_play) {
        if (this._play !== _play) {
            this._play = _play;
            if (this._play && this._gestureNavigating) {
                this.updateProgress();
            }
            if (this._inAnimation && this._outAnimation) {
                if (_play) {
                    this._inAnimation.play();
                    this._outAnimation.play();
                }
                else {
                    this._inAnimation.pause();
                    this._outAnimation.pause();
                }
            }
        }
    }
    set progress(_progress) {
        this._progress = _progress;
        if (this._onProgress) {
            this._onProgress(this._progress);
        }
        const currentTime = (this._progress / 100) * this._duration;
        if (this._inAnimation && this._outAnimation) {
            this._inAnimation.currentTime = currentTime;
            this._outAnimation.currentTime = currentTime;
        }
    }
    set currentScreen(_screen) {
        this._currentScreen = _screen;
    }
    set nextScreen(_screen) {
        this._nextScreen = _screen;
        if (!this._currentScreen) {
            _screen.mounted(true);
            this._nextScreen = null;
            // this.animate(this._currentScreen, this._nextScreen);
        }
    }
    set onEnter(_onEnter) {
        this._onEnter = _onEnter;
    }
    set onExit(_onExit) {
        this._onExit = _onExit;
    }
    set duration(_duration) {
        this._duration = _duration;
    }
    get progress() {
        return this._progress;
    }
    get gestureNavigating() {
        return this._gestureNavigating;
    }
}
export const AnimationLayerDataContext = createContext(new AnimationLayerData());
const OppositeDirection = {
    "left": "right",
    "right": "left",
    "up": "down",
    "down": "up",
    "in": "out",
    "out": "in"
};
export class AnimationProvider extends React.Component {
    constructor() {
        super(...arguments);
        this._animationLayerData = null;
        this.ref = null;
        this.setRef = this.onRef.bind(this);
        this.state = {
            mounted: false,
            pointerEvents: 'unset'
        };
    }
    onRef(ref) {
        this.ref = ref;
    }
    componentDidMount() {
        if (this._animationLayerData) {
            if (this.props.in) {
                this._animationLayerData.onEnter = this.props.onEnter;
                this._animationLayerData.nextScreen = this;
            }
            if (this.props.out) {
                this._animationLayerData.onExit = this.props.onExit;
                this._animationLayerData.currentScreen = this;
            }
        }
        // this.setState({mounted: this.props.in});
    }
    componentDidUpdate(prevProps) {
        if (!this._animationLayerData)
            return;
        if (this.props.out !== prevProps.out || this.props.in !== prevProps.in) {
            if (this.props.out) {
                // set current screen and call onExit
                this._animationLayerData.onExit = this.props.onExit;
                this._animationLayerData.currentScreen = this;
            }
            else if (this.props.in) {
                this._animationLayerData.onEnter = this.props.onEnter;
                this._animationLayerData.nextScreen = this;
            }
        }
    }
    get inAnimation() {
        let direction = this.props.animation.in.direction;
        let directionPrefix = '';
        const backNavigating = this.props.backNavigating;
        if (backNavigating && direction) {
            if (this.props.animation.in.type === "zoom" || this.props.animation.in.type === "slide") {
                direction = OppositeDirection[direction];
                directionPrefix = 'back-';
            }
        }
        switch (this.props.animation.in.type) {
            case "slide":
                return `slide-${directionPrefix + direction || 'left'}-in`;
            case "zoom":
                return `zoom-${direction || 'in'}-in`;
            case "fade":
                return "fade-in";
            default:
                return "none";
        }
    }
    get outAnimation() {
        let direction = this.props.animation.out.direction;
        let directionPrefix = '';
        const backNavigating = this.props.backNavigating;
        if (backNavigating && direction) {
            if (this.props.animation.out.type === "zoom" || this.props.animation.out.type === "slide") {
                direction = OppositeDirection[direction];
                directionPrefix = 'back-';
            }
        }
        switch (this.props.animation.out.type) {
            case "slide":
                return `slide-${directionPrefix + direction || 'left'}-out`;
            case "zoom":
                return `zoom-${direction || 'in'}-out`;
            case "fade":
                return "fade-out";
            default:
                return "none";
        }
    }
    animate(keyframes, options) {
        var _a;
        const animation = (_a = this.ref) === null || _a === void 0 ? void 0 : _a.animate(keyframes, options);
        animation === null || animation === void 0 ? void 0 : animation.pause();
        return animation;
    }
    mounted(_mounted) {
        return new Promise((resolve, _) => {
            this.setState({ mounted: _mounted }, () => {
                var _a, _b;
                if (_mounted) {
                    const shouldScroll = Boolean((this.props.in && !((_a = this._animationLayerData) === null || _a === void 0 ? void 0 : _a.gestureNavigating))
                        || (this.props.out && ((_b = this._animationLayerData) === null || _b === void 0 ? void 0 : _b.gestureNavigating)));
                    if (this.props.onEnter) {
                        this.props.onEnter(shouldScroll);
                    }
                }
                resolve();
            });
        });
    }
    set pointerEvents(_pointerEvents) {
        this.setState({ pointerEvents: _pointerEvents });
    }
    render() {
        var _a, _b;
        let gestureEndState = {};
        if (((_a = this._animationLayerData) === null || _a === void 0 ? void 0 : _a.gestureNavigating) && this.props.in) {
            gestureEndState = AnimationKeyframePresets[this.inAnimation][0];
        }
        if (((_b = this._animationLayerData) === null || _b === void 0 ? void 0 : _b.gestureNavigating) && this.props.out) {
            gestureEndState = AnimationKeyframePresets[this.outAnimation][0];
        }
        return (React.createElement("div", { className: "animation-provider", ref: this.setRef, style: Object.assign({ position: 'absolute', transformOrigin: 'center center', pointerEvents: this.state.pointerEvents, touchAction: this.state.pointerEvents, zIndex: this.props.in && !this.props.backNavigating ? 1 : this.props.out && this.props.backNavigating ? 1 : 0 }, gestureEndState // so the "old" nextScreen doesn't snap back to centre
            ) },
            React.createElement(AnimationLayerDataContext.Consumer, null, (animationLayerData) => {
                this._animationLayerData = animationLayerData;
                if (this.state.mounted) {
                    return this.props.children;
                }
                else {
                    return React.createElement(React.Fragment, null);
                }
            })));
    }
}
export const Motion = createContext(0);
// type of children coerces type in React.Children.map such that 'path' is available on props
export default class AnimationLayer extends React.Component {
    constructor() {
        super(...arguments);
        this.onSwipeStartListener = this.onSwipeStart.bind(this);
        this.onSwipeListener = this.onSwipe.bind(this);
        this.onSwipeEndListener = this.onSwipeEnd.bind(this);
        this.animationLayerData = new AnimationLayerData();
        this.state = {
            currentPath: this.props.currentPath,
            children: this.props.children,
            progress: 0,
            shouldPlay: true,
            gestureNavigation: false,
            shouldAnimate: true
        };
    }
    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.currentPath !== state.currentPath) {
            if (!state.shouldAnimate) {
                return {
                    currentPath: nextProps.currentPath,
                    shouldAnimate: true
                };
            }
            return {
                children: React.Children.map(nextProps.children, (child) => {
                    if (React.isValidElement(child)) {
                        if (child.props.path === nextProps.currentPath) {
                            const element = React.cloneElement(child, Object.assign(Object.assign({}, child.props), { in: true, out: false }));
                            return element;
                        }
                        else if (child.props.path === state.currentPath) {
                            const element = React.cloneElement(child, Object.assign(Object.assign({}, child.props), { out: true, in: false }));
                            return element;
                        }
                        else {
                            return undefined;
                        }
                    }
                }).sort((child, _) => child.props.path === nextProps.currentPath ? 1 : -1),
                currentPath: nextProps.currentPath
            };
        }
        return state;
    }
    componentDidMount() {
        this.animationLayerData.duration = this.props.duration;
        this.animationLayerData.onProgress = (_progress) => {
            const progress = this.props.backNavigating && !this.state.gestureNavigation ? 99 - _progress : _progress;
            this.setState({ progress: clamp(progress, 0, 100) });
            const progressEvent = new CustomEvent('motion-progress', {
                detail: {
                    progress: progress
                }
            });
            window.queueMicrotask(() => {
                dispatchEvent(progressEvent);
            });
        };
        if (!this.props.disableDiscovery) {
            window.addEventListener('swipestart', this.onSwipeStartListener);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentPath !== this.state.currentPath) {
            this.animationLayerData.duration = this.props.duration;
            if (!this.state.gestureNavigation) {
                this.animationLayerData.play = true;
                this.animationLayerData.animate(); // children changes committed now animate
            }
        }
    }
    componentWillUnmount() {
        if (!this.props.disableDiscovery) {
            window.removeEventListener('swipestart', this.onSwipeStartListener);
        }
    }
    onSwipeStart(ev) {
        // if only one child return
        if (!this.props.lastPath)
            return;
        if (ev.direction === "right" && ev.x < this.props.swipeAreaWidth) {
            const children = React.Children.map(this.props.children, (child) => {
                if (React.isValidElement(child)) {
                    if (child.props.path === this.props.currentPath || child.props.path === this.props.lastPath) {
                        const _in = child.props.path === this.props.currentPath ? true : false;
                        const element = React.cloneElement(child, Object.assign(Object.assign({}, child.props), { in: _in, out: !_in }));
                        return element;
                    }
                }
                else {
                    return undefined;
                }
            }).sort((firstChild) => firstChild.props.path === this.props.currentPath ? -1 : 1);
            this.setState({
                shouldPlay: false,
                gestureNavigation: true,
                children: children
            }, () => {
                const motionStartEvent = new CustomEvent('motion-progress-start');
                this.animationLayerData.gestureNavigating = true;
                this.animationLayerData.playbackRate = -1;
                this.animationLayerData.play = false;
                this.animationLayerData.animate();
                window.dispatchEvent(motionStartEvent);
                window.addEventListener('swipe', this.onSwipeListener);
                window.addEventListener('swipeend', this.onSwipeEndListener);
            });
        }
    }
    onSwipe(ev) {
        if (this.state.shouldPlay)
            return;
        const progress = (Math.abs(ev.x - window.innerWidth) / window.innerWidth) * 100;
        this.animationLayerData.progress = progress;
    }
    onSwipeEnd(ev) {
        if (this.state.shouldPlay)
            return;
        let onEnd = null;
        const motionEndEvent = new CustomEvent('motion-progress-end');
        if (this.state.progress < this.props.hysteresis || ev.velocity > this.props.minFlingVelocity) {
            if (ev.velocity >= this.props.minFlingVelocity) {
                this.animationLayerData.playbackRate = -4;
            }
            onEnd = () => {
                if (!this.props.disableBrowserRouting)
                    this.animationLayerData.shouldAnimate = false;
                this.animationLayerData.reset();
                this.props.goBack();
                this.setState({ gestureNavigation: false });
                window.dispatchEvent(motionEndEvent);
            };
            this.setState({ shouldPlay: true, shouldAnimate: false });
        }
        else {
            this.animationLayerData.playbackRate = 0.5;
            onEnd = () => {
                this.animationLayerData.reset();
                window.dispatchEvent(motionEndEvent);
            };
            this.setState({ shouldPlay: true, gestureNavigation: false });
        }
        this.animationLayerData.onEnd = onEnd;
        this.animationLayerData.play = true;
        window.removeEventListener('swipe', this.onSwipeListener);
        window.removeEventListener('swipeend', this.onSwipeEndListener);
    }
    render() {
        return (React.createElement(AnimationLayerDataContext.Provider, { value: this.animationLayerData },
            React.createElement(Motion.Provider, { value: this.state.progress }, this.state.children)));
    }
}

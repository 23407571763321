import React, { createContext } from 'react';
import { getCSSText } from './common/utils';
import assert from 'assert';
var SharedElement;
(function (SharedElement_1) {
    //https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin#formal_syntax
    //https://stackoverflow.com/questions/51445767/how-to-define-a-regex-matched-string-type-in-typescript
    let TransformOriginKeywordEnum;
    (function (TransformOriginKeywordEnum) {
        TransformOriginKeywordEnum[TransformOriginKeywordEnum["top"] = 0] = "top";
        TransformOriginKeywordEnum[TransformOriginKeywordEnum["bottom"] = 1] = "bottom";
        TransformOriginKeywordEnum[TransformOriginKeywordEnum["left"] = 2] = "left";
        TransformOriginKeywordEnum[TransformOriginKeywordEnum["right"] = 3] = "right";
    })(TransformOriginKeywordEnum || (TransformOriginKeywordEnum = {}));
    ;
    let TransformOriginLengthUnitEnum;
    (function (TransformOriginLengthUnitEnum) {
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["cap"] = 0] = "cap";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["ch"] = 1] = "ch";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["em"] = 2] = "em";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["ex"] = 3] = "ex";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["ic"] = 4] = "ic";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["lh"] = 5] = "lh";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["rem"] = 6] = "rem";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["rlh"] = 7] = "rlh";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["vh"] = 8] = "vh";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["vw"] = 9] = "vw";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["vi"] = 10] = "vi";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["vb"] = 11] = "vb";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["vmin"] = 12] = "vmin";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["vmax"] = 13] = "vmax";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["px"] = 14] = "px";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["cm"] = 15] = "cm";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["mm"] = 16] = "mm";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["Q"] = 17] = "Q";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["in"] = 18] = "in";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["pc"] = 19] = "pc";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["pt"] = 20] = "pt";
        TransformOriginLengthUnitEnum[TransformOriginLengthUnitEnum["%"] = 21] = "%";
    })(TransformOriginLengthUnitEnum || (TransformOriginLengthUnitEnum = {}));
    let TransformOriginGlobalEnum;
    (function (TransformOriginGlobalEnum) {
        TransformOriginGlobalEnum[TransformOriginGlobalEnum["inital"] = 0] = "inital";
        TransformOriginGlobalEnum[TransformOriginGlobalEnum["inherit"] = 1] = "inherit";
        TransformOriginGlobalEnum[TransformOriginGlobalEnum["revert"] = 2] = "revert";
        TransformOriginGlobalEnum[TransformOriginGlobalEnum["unset"] = 3] = "unset";
    })(TransformOriginGlobalEnum || (TransformOriginGlobalEnum = {}));
    let EasingFunctionKeywordEnum;
    (function (EasingFunctionKeywordEnum) {
        EasingFunctionKeywordEnum[EasingFunctionKeywordEnum["ease"] = 0] = "ease";
        EasingFunctionKeywordEnum[EasingFunctionKeywordEnum["ease-in"] = 1] = "ease-in";
        EasingFunctionKeywordEnum[EasingFunctionKeywordEnum["ease-in-out"] = 2] = "ease-in-out";
        EasingFunctionKeywordEnum[EasingFunctionKeywordEnum["ease-out"] = 3] = "ease-out";
    })(EasingFunctionKeywordEnum || (EasingFunctionKeywordEnum = {}));
    let TransitionAnimationEnum;
    (function (TransitionAnimationEnum) {
        TransitionAnimationEnum[TransitionAnimationEnum["morph"] = 0] = "morph";
        TransitionAnimationEnum[TransitionAnimationEnum["fade-through"] = 1] = "fade-through";
        TransitionAnimationEnum[TransitionAnimationEnum["fade"] = 2] = "fade";
        TransitionAnimationEnum[TransitionAnimationEnum["cross-fade"] = 3] = "cross-fade";
    })(TransitionAnimationEnum || (TransitionAnimationEnum = {}));
    class Scene {
        constructor(name) {
            this._nodes = {};
            this._name = '';
            this._scrollPos = null;
            this._x = 0;
            this._y = 0;
            this._name = name;
        }
        addNode(node) {
            if (!node)
                return;
            assert(!Object.keys(this.nodes).includes(node.id), `Duplicate Shared Element ID: ${node.id} in ${this._name}`);
            this._nodes[node.id] = node;
        }
        removeNode(_id) {
            delete this._nodes[_id];
        }
        get nodes() {
            return this._nodes;
        }
        get name() {
            return this._name;
        }
        get scrollPos() {
            return this._scrollPos || {
                x: 0,
                y: 0
            };
        }
        get x() {
            return this._x;
        }
        get y() {
            return this._y;
        }
        set scrollPos(_scrollPos) {
            this._scrollPos = _scrollPos;
        }
        set x(_x) {
            this._x = _x;
        }
        set y(_y) {
            this._y = _y;
        }
        isEmpty() {
            return !Object.keys(this._nodes).length ? true : false;
        }
    }
    SharedElement_1.Scene = Scene;
    SharedElement_1.SceneContext = createContext(null);
    function nodeFromRef(id, ref, instance) {
        const node = ref.cloneNode(true);
        const firstChild = node.firstElementChild;
        if (!firstChild)
            return null;
        const computedStyle = instance.computedStyle;
        const clientRect = instance.clientRect;
        firstChild.style.cssText = getCSSText(computedStyle);
        if (instance.props.config && instance.props.config.transformOrigin) {
            firstChild.style.transformOrigin = instance.props.config.transformOrigin;
        }
        /**
         * Translate X on outer element and translate Y on inner element
         * allows for layered animations
         */
        firstChild.style.transform = `translateY(${clientRect.y}px)`;
        node.style.transform = `translateX(${clientRect.x}px)`;
        node.style.willChange = 'contents, transform';
        node.style.position = 'absolute';
        firstChild.style.position = 'absolute';
        node.style.zIndex = firstChild.style.zIndex;
        node.style.top = '0';
        node.style.left = '0';
        node.setAttribute('x', `${clientRect.x}px`);
        node.setAttribute('y', `${clientRect.y}px`);
        /**
         * TODO:
         * i.e. if slide is horizontal (left|right) change to translateY or if slide is vertical (up|down) change to translateX
         *
         * 2̶.̶ C̶o̶m̶p̶e̶n̶s̶a̶t̶e̶ f̶o̶r̶ t̶r̶a̶v̶e̶l̶ d̶i̶s̶t̶a̶n̶c̶e̶ d̶u̶e̶ t̶o̶ w̶i̶n̶d̶o̶w̶ s̶c̶r̶o̶l̶l̶ p̶o̶s̶i̶t̶i̶o̶n̶
         */
        return {
            id: id,
            node: node,
            instance: instance
        };
    }
    class SharedElement extends React.Component {
        constructor() {
            super(...arguments);
            this._id = this.props.id.toString();
            this.ref = null;
            this._scene = null;
            this._hidden = false;
            this._isMounted = false;
            this.onRef = this.setRef.bind(this);
        }
        get scene() {
            return this._scene;
        }
        get clientRect() {
            if (this.ref && this.ref.firstChild) {
                const clientRect = this.ref.firstChild.getBoundingClientRect();
                return clientRect;
            }
            return new DOMRect();
        }
        get computedStyle() {
            if (this.ref && this.ref.firstChild) {
                const computedStyles = window.getComputedStyle(this.ref.firstChild);
                return computedStyles;
            }
            return new CSSStyleDeclaration();
        }
        get cssText() {
            const computedStyle = this.computedStyle;
            if (computedStyle)
                return getCSSText(computedStyle);
            return '';
        }
        get id() {
            return this._id;
        }
        get hidden() {
            return this._hidden;
        }
        get transitionType() {
            var _a;
            return (_a = this.props.config) === null || _a === void 0 ? void 0 : _a.type;
        }
        set hidden(_hidden) {
            this._hidden = _hidden;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }
        setRef(ref) {
            var _a, _b;
            if (this.ref !== ref) {
                if (this.ref) {
                    (_a = this.scene) === null || _a === void 0 ? void 0 : _a.removeNode(this._id);
                }
                this.ref = ref;
                if (ref) {
                    (_b = this.scene) === null || _b === void 0 ? void 0 : _b.addNode(nodeFromRef(this._id, ref, this));
                }
            }
        }
        componentDidMount() {
            this._isMounted = true;
        }
        componentDidUpdate() {
            var _a, _b;
            if (this._id !== this.props.id) {
                if (this.ref) {
                    (_a = this.scene) === null || _a === void 0 ? void 0 : _a.removeNode(this._id);
                    this._id = this.props.id.toString();
                    (_b = this.scene) === null || _b === void 0 ? void 0 : _b.addNode(nodeFromRef(this._id, this.ref, this));
                }
            }
        }
        componentWillUnmount() {
            this._isMounted = false;
        }
        render() {
            return (React.createElement(SharedElement_1.SceneContext.Consumer, null, (scene) => {
                this._scene = scene;
                return (React.createElement("div", { ref: this.onRef, id: `shared-element-${this._id}`, className: "shared-element", style: {
                        display: this._hidden ? 'block' : 'contents',
                        opacity: this._hidden ? '0' : '1'
                    } }, this.props.children));
            }));
        }
    }
    SharedElement_1.SharedElement = SharedElement;
})(SharedElement || (SharedElement = {}));
export default SharedElement;

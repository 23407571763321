import React from 'react';
import SharedElement from './SharedElement';
import { RouterDataContext } from './Router';
import { AnimationProvider } from './AnimationLayer';
export var Stack;
(function (Stack) {
    class Screen extends React.Component {
        constructor() {
            super(...arguments);
            this.sharedElementScene = new SharedElement.Scene(this.props.component.name);
            this.ref = null;
            this.observer = new ResizeObserver(this.observe.bind(this));
            this.onRef = this.setRef.bind(this);
            this.animation = {
                in: {
                    type: 'none',
                    duration: 0
                },
                out: {
                    type: 'none',
                    duration: 0
                }
            };
            this.scrollPos = {
                x: 0,
                y: 0
            };
            this.state = {
                _in: false,
                xOverflow: false,
                yOverflow: true
            };
        }
        componentDidMount() {
            var _a;
            this.setState({ _in: Boolean(this.props.path === this.context.currentPath) });
            if ((_a = this.props.config) === null || _a === void 0 ? void 0 : _a.animation) {
                if ('in' in this.props.config.animation) {
                    this.animation = {
                        in: this.props.config.animation.in,
                        out: this.props.config.animation.out || this.props.config.animation.in
                    };
                }
                else {
                    this.animation = {
                        in: this.props.config.animation,
                        out: this.props.config.animation
                    };
                }
            }
            else {
                this.animation = this.context.animation;
            }
        }
        componentDidUpdate() {
            if (this.props.path !== this.context.currentPath) {
                if (!this.state._in) {
                    this.setState({ _in: true });
                }
            }
            else {
                if (this.state._in) {
                    this.setState({ _in: false });
                }
            }
        }
        componentWillUnmount() {
            if (this.ref) {
                this.observer.unobserve(this.ref);
            }
        }
        observe(entries) {
            if (entries.length) {
                const xOverflow = entries[0].target.scrollWidth > window.innerWidth;
                const yOverflow = entries[0].target.scrollHeight > window.innerHeight;
                if (xOverflow !== this.state.xOverflow) {
                    this.setState({ xOverflow: xOverflow });
                }
                if (yOverflow !== this.state.yOverflow) {
                    this.setState({ yOverflow: yOverflow });
                }
            }
        }
        onExit() {
            if (this.ref) {
                this.scrollPos = {
                    x: this.ref.scrollLeft,
                    y: this.ref.scrollTop
                };
                this.sharedElementScene.scrollPos = this.scrollPos;
            }
            if (this.context.ghostLayer) {
                this.context.ghostLayer.currentScene = this.sharedElementScene;
            }
        }
        onEnter(shouldScroll) {
            var _a;
            if (shouldScroll)
                (_a = this.ref) === null || _a === void 0 ? void 0 : _a.scrollTo(this.scrollPos.x, this.scrollPos.y);
            if (this.context.ghostLayer) {
                this.context.ghostLayer.nextScene = this.sharedElementScene;
            }
        }
        setRef(ref) {
            if (this.ref !== ref) {
                if (this.ref) {
                    this.observer.unobserve(this.ref);
                }
                this.ref = ref;
                if (ref) {
                    const clientRect = ref.getBoundingClientRect();
                    this.sharedElementScene.x = clientRect.x;
                    this.sharedElementScene.y = clientRect.y;
                    this.observer.observe(ref);
                }
            }
        }
        render() {
            return (React.createElement(AnimationProvider, { onExit: this.onExit.bind(this), onEnter: this.onEnter.bind(this), in: this.props.in || false, out: this.props.out || false, name: this.props.path, animation: this.animation, backNavigating: this.context.backNavigating },
                React.createElement("div", { ref: this.onRef, className: "screen-content", style: {
                        height: '100vh',
                        minWidth: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        willChange: 'transform, opacity',
                        touchAction: 'inherit',
                        overflowX: this.state.xOverflow ? 'scroll' : undefined,
                        overflowY: this.state.yOverflow ? 'scroll' : undefined
                    } },
                    React.createElement(SharedElement.SceneContext.Provider, { value: this.sharedElementScene },
                        React.createElement(this.props.component, { route: Object.assign({ params: Object.assign({}, this.props.defaultParams) }, this.context.routesData[this.props.path]), navigation: this.context.navigation })))));
        }
    }
    Screen.contextType = RouterDataContext;
    Screen.defaultProps = {
        route: {
            params: {}
        }
    };
    Stack.Screen = Screen;
})(Stack || (Stack = {}));

export class History {
    constructor() {
        this._stack = [];
        this._next = null;
        this._defaultRoute = null;
        this._stack.push(window.location.pathname);
    }
    set defaultRoute(_defaultRoute) {
        this._defaultRoute = _defaultRoute;
    }
    get current() {
        return this._stack[this._stack.length - 1];
    }
    get length() {
        return this._stack.length;
    }
    get defaultRoute() {
        return this._defaultRoute;
    }
    get next() {
        return this._next;
    }
    get previous() {
        if (this._stack.length > 1) {
            return this._stack[this._stack.length - 2];
        }
        return null;
    }
    get isEmpty() {
        return !this._stack.length ? true : false;
    }
    push(route) {
        this._next = route;
        window.history.pushState({}, "", route);
        this._stack.push(route);
    }
    implicitPush(route) {
        this._next = route;
        this._stack.push(route);
    }
    back(replaceState) {
        this._next = this._stack.pop() || null;
        if (replaceState && this._defaultRoute) {
            this._stack.push(this._defaultRoute);
            window.history.replaceState({}, "", this._defaultRoute);
        }
        else {
            window.history.back();
        }
        return this.previous;
    }
    implicitBack() {
        this._next = this._stack.pop() || null;
        return this.previous;
    }
    searchParamsToObject(searchPart) {
        const entries = new URLSearchParams(decodeURI(searchPart)).entries();
        const result = {};
        for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            let parsedValue = '';
            try {
                parsedValue = JSON.parse(value);
            }
            catch (e) {
                console.warn("Non JSON seralisable value was passed as URL route param.");
                parsedValue = value;
            }
            result[key] = parsedValue;
        }
        return Object.keys(result).length ? result : undefined;
    }
}
export class Navigation {
    constructor(_disableBrowserRouting) {
        this._history = new History();
        this._disableBrowserRouting = _disableBrowserRouting;
    }
    navigate(route, routeParams) {
        if (this._disableBrowserRouting) {
            this._history.implicitPush(route);
        }
        else {
            this._history.push(route);
        }
        const event = new CustomEvent('navigate', {
            detail: {
                route: route,
                routeParams: routeParams
            }
        });
        window.dispatchEvent(event);
    }
    implicitNavigate(route, routeParams) {
        this._history.implicitPush(route);
        const event = new CustomEvent('navigate', {
            detail: {
                route: route,
                routeParams: routeParams
            }
        });
        window.dispatchEvent(event);
    }
    implicitBack() {
        this._history.implicitBack();
    }
    goBack() {
        let event = new CustomEvent('go-back', {
            detail: {
                replaceState: false
            }
        });
        if (this._history.defaultRoute && this._history.length === 1) {
            this._history.back(true);
            event = new CustomEvent('go-back', {
                detail: {
                    replaceState: true
                }
            });
        }
        else {
            if (this._disableBrowserRouting) {
                this._history.implicitBack();
            }
            else {
                this._history.back();
            }
        }
        window.dispatchEvent(event);
    }
    get history() {
        return this._history;
    }
    get location() {
        return Object.assign(Object.assign({}, window.location), { pathname: this._history.current });
    }
}
export function getCSSText(styles) {
    if (styles.cssText !== '') {
        return styles.cssText;
    }
    else {
        const cssText = Object.values(styles).reduce((css, propertyName) => `${css}${propertyName}:${styles.getPropertyValue(propertyName)};`);
        return cssText;
    }
}
export function getStyleObject(styles) {
    const styleObject = {};
    for (const key in styles) {
        if (styles[key] && styles[key].length && typeof styles[key] !== "function") {
            if (/^\d+$/.test(key))
                continue;
            if (key === "offset")
                continue;
            styleObject[key] = styles[key];
        }
    }
    return styleObject;
}
export function clamp(num, min, max) {
    if (num < min) {
        return min;
    }
    else if (max && num > max) {
        return max;
    }
    return num;
}
